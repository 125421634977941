<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<el-row :gutter="10" class="mb-1 text-price">
				*如需复制，双击复制列
			</el-row>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
				@cell-dblclick="doubleclick"
			 >
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="70" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">已处理</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待处理</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="200" align="center" prop="name">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="手机" min-width="110" align="center" prop="mobile">
				  <template slot-scope="scope">
					{{ scope.row.mobile }}
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center" prop="type">
				  <template slot-scope="scope">
					{{ scope.row.type_txt }}
				  </template>
				</el-table-column>
				<el-table-column label="内容" min-width="110" align="center" :show-overflow-tooltip="true" prop="desc">
				  <template slot-scope="scope">
					{{ scope.row.desc }}
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
				  <template slot-scope="scope">
					  <el-tooltip class="item" effect="dark" content="记录" placement="top" v-if="scope.row.status == 1">
					    <el-button type="success" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					  </el-tooltip>
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="scope.row.status == 2">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleOperate(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 审核-->
		<el-dialog :visible.sync="operate" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="operate" :model="operateForm" label-width="80px" size="small" :rules="rules">
			    <el-form-item  label="审核状态" prop="status">
					<el-radio-group v-model="operateForm.status">
					   <el-radio :label="1">已处理</el-radio>
					   <el-radio :label="2">待处理</el-radio>
					 </el-radio-group>
			    </el-form-item>
				<el-form-item label="反馈结果" prop="content" v-if="operateForm.status == 1">
					<el-input v-model="operateForm.content" placeholder="请输入反馈结果" type="textarea" clearable rows="3" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="operateCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 记录-->
		<el-dialog :visible.sync="view" width="600px" :close-on-click-modal="false" append-to-body :title="title">
            <div class="w-100 flex flex-column">
            	<div class="w-100 flex border-bottom py-1 bx">
            		<div class="flex-1 h-100 flex align-center justify-left">
            			名称
            		</div>
					<div class="flex-1 h-100 flex align-center">
						{{info.name || ''}}
					</div>
            	</div>
				<div class="w-100 flex border-bottom py-1 bx">
					<div class="flex-1 h-100 flex align-center justify-left">
						手机
					</div>
					<div class="flex-1 h-100 flex align-center">
						{{info.mobile || ''}}
					</div>
				</div>
				<div class="w-100 flex border-bottom py-1 bx">
					<div class="flex-1 h-100 flex align-center justify-left">
						问题
					</div>
					<div class="flex-1 flex align-left">
						{{info.desc || ''}}
					</div>
				</div>
				<div class="w-100 flex border-bottom py-1 bx">
					<div class="flex-1 h-100 flex align-center justify-left">
						回复
					</div>
					<div class="flex-1 flex align-left">
						{{info.content || ''}}
					</div>
				</div>
				<div class="w-100 flex border-bottom py-1 bx">
					<div class="flex-1 h-100 flex align-center justify-left">
						处理人
					</div>
					<div class="flex-1 flex align-left">
						{{info.manage || ''}}
					</div>
				</div>
				<div class="w-100 flex border-bottom py-1 bx">
					<div class="flex-1 h-100 flex align-center justify-left">
						处理时间
					</div>
					<div class="flex-1 flex align-left">
						{{info.utime || ''}}
					</div>
				</div>
            </div>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="view = false">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'feedback',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				rules:{
					status: [
					  { required: true, message: '请选择审核状态', trigger: 'blur' }
					],
				},
				operate:false,
				operates:[
					{id:1,name:'已处理'},
					{id:2,name:'待处理'},
				],
				operateForm:{
					id:'',
					status:'',
					content:'',
				},
				view:false,
				info:{},
			}
		},
		mounted() {
			
		},
		methods:{
			reset() {
			  this.operateForm = {
			   id:'',
			   status:'',
			   content:'',
			  }
			  this.resetForm('form')
			},
			handleOperate(row){
				this.operate = true
				this.title = '审核'
				this.reset()
				this.operateForm.id = row.id
			},
			operateCancel(){
				this.operate = false
			},
			handleView(item){
				this.title = '审核记录'
				this.view = true
				this.info = item
			},
			handleOperateSubmit(){
				this.$refs.operate.validate(valid => {
				  if (valid) {
					  this.axios.post('/manage/feedback/operate', this.operateForm).then(res => {
					    if (res.status) {
					  	this.$message.success('审核成功')
					  	this.operate = false
					  	this.getList()
					    } else {
					  	this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
		  doubleclick(row, column, cell, event){
			  //双击的是那个单元格
			  if (column.property == "name") {
				const text = document.createElement('textarea'); // 创建节点
				text.setAttribute('readonly', 'readonly');
				text.value = row.name; // 赋值
				document.body.appendChild(text); // 插入节点
				text.setSelectionRange(0, text.value.length);
				text.select(); // 选中节点
				document.execCommand('copy'); // 执行浏览器复制方法
				if (document.body.removeChild(text)) {
				  this.$message.success('复制成功')
				} else {
				  this.$message.error('复制失败')
				}
			  }else if (column.property == "desc") {
				const text = document.createElement('textarea'); // 创建节点
				text.setAttribute('readonly', 'readonly');
				text.value = row.desc; // 赋值
				document.body.appendChild(text); // 插入节点
				text.setSelectionRange(0, text.value.length);
				text.select(); // 选中节点
				document.execCommand('copy'); // 执行浏览器复制方法
				if (document.body.removeChild(text)) {
				  this.$message.success('复制成功')
				} else {
				  this.$message.error('复制失败')
				}
			  }else if (column.property == "mobile") {
				const text = document.createElement('textarea'); // 创建节点
				text.setAttribute('readonly', 'readonly');
				text.value = row.mobile; // 赋值
				document.body.appendChild(text); // 插入节点
				text.setSelectionRange(0, text.value.length);
				text.select(); // 选中节点
				document.execCommand('copy'); // 执行浏览器复制方法
				if (document.body.removeChild(text)) {
				  this.$message.success('复制成功')
				} else {
				  this.$message.error('复制失败')
				}
			  }
			},
		}
	}
</script>

<style>
</style>